@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingBreakdownTitle,
.registeredPlayersHeader {
  letter-spacing: 1px;
  font-weight: var(--fontWeightMedium);
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.bookingDates {
  flex-shrink: 0;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--colorFail);
  margin: 0 24px;
  display: inline-block;
}

.sideBarError {
  composes: h4 from global;
  color: var(--colorFail);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 12px 0;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
  margin-top: 8px;
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;

  & > button {
    background: #20c84b;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }

  & .requestToBookOnSite {
    margin-top: 24px;
  }
}

.registeredPlayersText {
  composes: marketplaceSmallFontStyles from global;
  margin-bottom: 40px;
}

.registeredPlayersLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}

.playersList {
  margin-top: 24px;

  & .player {
    display: flex;
    margin-top: 10px;
    line-height: 32px;

    & .playerAvatar {
      margin-right: 10px;
    }
  }
}

.linkToExternalWebPage {
  composes: buttonPrimary from global;
  margin-top: 24px;
  @media(max-width:768px) {
   width: 90%;
   margin: 24px auto;
  }
}

.addCardInformation {
  margin-top: 10px;
}

.cardInformation {
  font-size: 11px;
  margin-left: 6px;
}

.greenButton {
  background: #2ecc71;

  &:hover {
    background: #2ecc71;
  }
}

.blueButton {
  background: #0579e2;

  &:hover {
    background: #0579e2;
  }
}

.bookedSlots {
  text-align: center;
  margin: 5px;
  background: #ececec;
  line-height: 30px;
  margin: 0px 22px;
  cursor: pointer;
}

.bookedDateValidation {
  font-size: 13px;
  color: #1a8cf6;
  font-weight: 700;
  @media (max-width: 768px) {
    padding: 0px 24px;
  }
}

.bookingListDetails {
  margin-top: 26px;

  @media (max-width: 768px) {
    padding: 0px 24px;
  }

  & > h3 {
    letter-spacing: 1px;
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
  }

  & > div {
    & > p {
      font-size: 14px;
    }
  }
}

.playersList {
  margin-top: 24px;

  & .player {
    display: flex;
    margin-top: 10px;
    line-height: 32px;

    & .playerAvatar {
      margin-right: 10px;
    }
  }
}

.playersDivider {
  composes: totalDivider;

  margin-bottom: 7px;
}

.dateTitle {
  @media (max-width: 768px) {
    padding: 0px 24px;
  }
}
.progressBarWrapper {
  display: flex;
}
.progressSection {
  margin-top: 10px;
}
.progressContainer {
  position: relative;
  width: 85%;
  height: 30px; /* Adjust height of the progress bar */
  background-color: #f3f3f3; /* Light gray background */
  border-radius: 0;
  border: 2px solid var(--marketplaceColor);
  cursor: pointer;

  @media(max-width:460px) {
    width: 80%;
  }
  @media(max-width:375px) {
    width: 75%;
  }
}

.progressBar {
  height: 100%;
  background-color: #4caf50; /* Green color for progress */
  transition: width 0.3s ease-in-out; /* Smooth transition effect */
}

.progressText {
  position: absolute;
  right: 0;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  &:hover {
      &::first-letter {
      color: var(--marketplaceColor);
    }
  }
  @media(max-width:1024px) {
    right: 30px;
  }
}
.playersRegister {
  margin: 24px 0;
&>span {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
}
  & .playerNumber {
font-size: 18px;
padding-top: 10px;
font-weight: 500;
color: #000000;
  }
}